import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import user from '../assets/images/user.png';
import logo from '../assets/svg/cms.svg';

import en from '../assets/images/en.png';
import de from '../assets/images/de.png';
import ico from '../assets/images/ico.png';


class Header extends Component {

  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      langIco: props.lang === 'en' ? en : de 
    };
  }


  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }


  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        langs: null,
        account: null
      });
    }
  }


  render() {

    return (

      <header>

        <div className="hamburger" onClick={() => this.props.handleSidebar(!this.props.sidebar)}>
          <i className="mdi mdi-view-headline"></i>
        </div>

        {
          <div className="logo">
            <Isvg src={logo} />
          </div>
        }

        <div className="visit-site">
          <a target="_blank" href="https://rossen.se">{this.props.translate('Visit site')}</a>
        </div>


        <div className="user" onClick={() => this.setState({ account: !this.state.account })}>
          <span>{this.props.uData ? this.props.uData.username : ''}</span>
          <img src={user} />
          {this.state.account ?
            <ul className="menu" ref={(node) => this.wrapperRef = node}>
              <li onClick={() => this.props.setUserData(null)}><i className="mdi mdi-power" />{this.props.translate('LogOut')}</li>

            </ul>

            : null
          }

        </div>



      </header>
    )
  }

};

export default Header;